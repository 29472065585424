.Container {
    background-color: #f8f8ff !important;
    height: 100% !important;
    max-height: 784px !important;
    display: flex !important;
    flex-flow: column !important;
    overflow: hidden;
}

.Backdrop {
    z-index: 9999999 !important;
    color: #f8f8ff !important;
}

.AppBar {
    padding: .5rem !important;
    display: flex !important;
    flex-flow: row !important;
    align-items: center;
    justify-content: space-between !important;
    color: #f8f8ff !important;
}

.Shrink {
    flex: 0 1 30% !important;
}

.StatusIcon {
    flex: 1 0 20% !important;
    width: 20%;
}

.Content {
    padding: .5rem !important;
    height: 100% !important;
}

.ProfilePicture {
    padding-right: .5rem !important;
    height: 100% !important;
    position: relative !important;
}

.ProfilePictureCard {
    height: 100% !important;
    width: 100% !important;
}

.ProfilePictureMedia {
    height: 100% !important;
    width: 100% !important;
}

.ProfilePictureButtons {
    padding: 0 5% 5% 5% !important;
    position: absolute !important;
    top: unset !important;
    right: 0 !important;
    bottom: 0 !important;
    left: 0 !important;
}

.ProfilePictureButtons > button {
    width: 30% !important;
}

.ProfilePictureButtons > button:not(:last-child) {
    margin-right: 5% !important;
}

.ProfilePicture img {
    width: 100% !important;
}

.ParentCardContainer {
    height: 100% !important;
}

.ParentCardContainer > div:not(:first-child) {
    padding-top: .25rem !important;
}

.ParentCardContainer > div:not(:last-child) {
    padding-bottom: .25rem !important;
}

.ParentCard {
    display: flex !important;
    align-items: stretch !important;
    height: 100% !important;
}

.ParentCardMedia {
    flex: 1 0 50% !important;
}

.ParentCardContent {
    flex: 1 0 50% !important;
    padding: .5rem !important;
    display: flex !important;
    flex-flow: column !important;
}

.GrandParentContainer > div:not(:first-child) {
    padding-left: .25rem !important;
}

.GrandParentContainer > div:not(:last-child) {
    padding-right: .25rem !important;
}

.GrandParent {
    flex: 1 0 50% !important;
}

.GrandParent > a {
    display: block !important;
    width: 100% !important;
    height: 100% !important;
}

.Ring {
    display: flex;
    align-items: center;
}

@media only screen and (max-width: 600px) {
    .Container {
        max-height: unset !important;
        border-radius: 0 !important;
    }

    .AppBar {
        flex-wrap: wrap !important;
    }

    .Content {
        overflow: auto !important;
    }

    .ProfilePictureContainer {
        height: 125vw !important;
        padding-bottom: .5rem;
    }

    .ProfilePictureButtons {
        padding: 0 .5rem .5rem .5rem !important;
        position: absolute !important;
        top: unset !important;
        right: 0 !important;
        bottom: 0 !important;
        left: 0 !important;
    }

    .ProfilePictureButtons > button {
        font-size: .7rem !important;
        width: calc((100% / 3) - .5rem) !important;
    }

    .ProfilePictureButtons > button:not(:last-child) {
        margin-right: .5rem !important;
    }

    .ParentCardContainer {
        height: 125vw !important;
    }

    .Shrink {
        flex: 1 0 auto !important;
    }

    .Order_3 {
        order: 3 !important;
        flex: 1 0 100% !important;
        justify-content: space-between !important;
    }

    .Ring {
        font-size: 1.2rem !important;
    }
}
