.Container {
    width: 100% !important;
    height: 100% !important;
    max-height: 784px !important;
    display: flex !important;
    flex-wrap: wrap !important;
    justify-content: center !important;
    align-items: center !important;
    background-color: ghostwhite;
}

@media only screen and (max-width: 600px) {
    .Container {
        max-height: unset !important;
        border-radius: 0 !important;
    }
}

.Logo {
    text-align: center !important;
}

.Button {
    margin-top: 1rem !important;
}

.Button:not(:last-child) {
    border-color: rgba(255, 255, 255, 0.25) !important;
}

.Backdrop {
    z-index: 9999999 !important;
    color: #f8f8ff !important;
}

form input {
    height: unset !important;
}
