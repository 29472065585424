.Container {
    width: 100% !important;
    height: 100% !important;
    max-height: 784px !important;
    display: flex !important;
    flex-flow: column;
    justify-content: center !important;
    align-items: center !important;
    background-color: ghostwhite;
}

.Form {
    display: flex;
    flex-wrap: wrap;
    margin-top: 1rem;
}

.Form > * {
    flex: 1 0 100%;
}

.Form > *:not(:first-child) {
    margin-top: .5rem;
}

@media only screen and (max-width: 600px) {
    .Container {
        max-height: unset !important;
        border-radius: 0 !important;
    }
}
