html, body, #root {
    margin: 0;
    width: 100%;
    height: 100%;
}

body {
    font-size: 12pt !important;
    font-family: Roboto !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

#root {
    display: flex;
    align-items: center;
    background-color: gainsboro;
}

* {
    box-sizing: border-box !important;
}
