.Container {
    background-color: #f8f8ff !important;
    height: 100% !important;
    max-height: 786px !important;
    display: flex !important;
    flex-flow: column !important;
    overflow: hidden !important;
}

.Backdrop {
    z-index: 9999999 !important;
    color: #f8f8ff !important;
}

.AppBar {
    padding: .5rem !important;
}

.Wrapper {
    display: flex !important;
    align-items: center !important;
    color: #f8f8ff !important;
}

.Shrink {
    flex: 0 1 0% !important;
}

.Grow {
    flex: 1 0 60% !important;
    padding: 0 1rem !important;
    color: #f8f8ff !important;
    display: flex !important;
    align-items: stretch !important;
}

.Grow > button {
    flex: 0 1 15% !important;
}

.FilterGrid {
    display: flex !important;
    flex-wrap: wrap !important;
}

.FilterGrid > * {
    flex: 1 0 30% !important;
    margin: calc(10% / 6) !important;
}

.FilterGrid legend {
    font-size: .8rem !important;
    width: 100% !important;
    position: relative !important;
}

.FilterGrid svg {
    position: absolute !important;
    right: 0 !important;
    opacity: calc(2/3) !important;
}

.Search {
    padding: 0 0.5rem !important;
    color: #f8f8ff !important;
    background-color: rgba(255, 255, 255, .15) !important;
    border-radius: 4px 0 0 4px !important;
    display: flex !important;
    align-items: center !important;
}

.Search input {
    padding: 0 !important;
}

.Search svg {
    width: unset !important;
    padding-right: 0.5rem !important;
}

.Button {
    background-color: rgba(255, 255, 255, .15) !important;
    border-left: thin solid rgba(255, 255, 255, .25) !important;
    border-radius: 0 4px 4px 0 !important;
}

.IconButton {
    color: #f8f8ff !important;
}

.ColorBar {
    position: absolute !important;
    top: unset !important;
    right: 0 !important;
    left: 0 !important;
    bottom: 0 !important;
    min-height: .4rem !important;
}

@media only screen and (max-width: 600px) {
    .Container {
        max-height: unset !important;
        border-radius: 0 !important;
    }

    .Wrapper {
        flex-wrap: wrap !important;
        justify-content: space-between !important;
    }

    .Shrink {
        flex: 0 1 10% !important;
    }

    .Grow {
        order: 3 !important;
        padding: 0 !important;
    }

    .Grow > button {
        flex-basis: calc(100% / 3) !important;
    }

    .FilterGrid > * {
        flex-basis: 40% !important;
        margin: 2.5% !important;
    }    
}
